import React from 'react'
import "./src/styles/global.css"
import { MainProvider } from './src/context/provider'
import { Toaster } from 'sonner'
import { Icon } from './src/config/icons'

export const wrapRootElement = ({ element }) => {
     return(
      <MainProvider>
        {element}
        <Toaster
        position="top-center"
        richColors
        icons={{
          success: <Icon.checkIcon />,
          warning: <Icon.warrnig />,
          error: <Icon.x />,
        }}
      />
      </MainProvider>
     )
}